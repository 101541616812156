import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { setUpTOTP, verifyTOTPSetup, updateMFAPreference } from 'aws-amplify/auth';
import { QRCodeSVG } from 'qrcode.react';

const MfaSetup = () => {
  const [qrCode, setQrCode] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [totpSecret, setTotpSecret] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { username, mfaPreference } = location.state || {};

  useEffect(() => {
    if (mfaPreference === 'totp') {
      setupTOTP();
    }
  }, [mfaPreference]);

  const setupTOTP = async () => {
    try {
      const setupResult = await setUpTOTP();
      console.log('Raw TOTP Setup Result:', JSON.stringify(setupResult, null, 2));

      // Check the structure
      const secretKey = setupResult?.secretKey || setupResult?.SharedSecret;
      if (!secretKey) {
        throw new Error(`No secret key in response: ${JSON.stringify(setupResult)}`);
      }

      await updateMFAPreference({
        preferred: 'TOTP',
        enabled: true
      });
      
      const userIdentifier = encodeURIComponent(username);
      const encodedIssuer = encodeURIComponent('Observant-Website');
      const totpUri = `otpauth://totp/${encodedIssuer}:${userIdentifier}?secret=${secretKey}&issuer=${encodedIssuer}`;
      
      setQrCode(totpUri);
      setTotpSecret(secretKey);
    } catch (error) {
      console.error('TOTP setup error:', error);
      setError('Failed to set up authenticator: ' + error.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      await verifyTOTPSetup({
        code: verificationCode,
      });
      navigate('/dashboard');
    } catch (err) {
      console.error('MFA setup error:', err);
      setError('Invalid verification code. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto px-6 py-12 bg-white dark:bg-gray-900 text-black dark:text-white">
      <h1 className="text-4xl font-bold mb-8">Set Up MFA</h1>
      <div className="max-w-md mx-auto">
        {mfaPreference === 'totp' && (
          <>
            <p className="mb-4 dark:text-gray-300">Scan this QR code with your authenticator app:</p>
            {qrCode && <QRCodeSVG value={qrCode} size={200} className="mb-6 mx-auto" />}
            <div className="mb-6 p-4 border rounded bg-gray-50 dark:bg-gray-800">
              <p className="font-semibold mb-2">Or enter this secret key manually:</p>
              <div className="font-mono bg-white dark:bg-gray-900 p-3 rounded-md border text-lg text-center select-all">
                {totpSecret}
              </div>
              <p className="text-sm text-gray-600 dark:text-gray-400 mt-2">
                Enter this secret key in your authenticator app if you can't scan the QR code
              </p>
            </div>
          </>
        )}
        {error && <p className="text-red-500 mb-4">{error}</p>}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="verificationCode" className="block mb-2 dark:text-gray-300">
              Enter verification code
            </label>
            <input
              type="text"
              id="verificationCode"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              className="w-full px-3 py-2 border rounded dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              required
              pattern="\\d{6}"
              maxLength={6}
            />
          </div>
          <button 
            type="submit" 
            className="w-full bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition duration-300"
            disabled={loading}
          >
            {loading ? 'Verifying...' : 'Complete Setup'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default MfaSetup;