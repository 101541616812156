import { Amplify } from 'aws-amplify';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';

const config = {
  Auth: {
    Cognito: {
      userPoolId: 'us-east-1_HW0Rydv9U',
      userPoolClientId: '37c7lih7ikq2g5dt3oibhhv7j0',
      signUpVerificationMethod: 'code',
      loginWith: {
        email: true,
        username: true,
        phone: false
      }
    }
  }
};

Amplify.configure(config);

// Handle existing tokens
cognitoUserPoolsTokenProvider.setKeyValueStorage({
  getItem(key) {
    return localStorage.getItem(key);
  },
  setItem(key, value) {
    localStorage.setItem(key, value);
  },
  removeItem(key) {
    localStorage.removeItem(key);
  }
});

export default config;