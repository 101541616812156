import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { confirmSignUp } from 'aws-amplify/auth';

const ConfirmSignUp = () => {
  const [confirmationCode, setConfirmationCode] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const username = location.state?.username;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!username) {
      setError('Username not found. Please return to registration.');
      return;
    }
    
    setLoading(true);
    setError('');

    try {
      await confirmSignUp({
        username,
        confirmationCode
      });

      navigate('/login', { 
        state: { message: 'Email confirmed successfully. You can now log in.' } 
      });
    } catch (err) {
      console.error('Confirmation error:', err);
      setError(err.message || 'Failed to confirm sign up');
    } finally {
      setLoading(false);
    }
  };

  if (!username) {
    return (
      <div className="container mx-auto px-6 py-12 bg-gray-100 dark:bg-gray-900">
        <p className="text-red-500 text-center">No username found. Please return to registration.</p>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-6 py-12 bg-gray-100 dark:bg-gray-900">
      <h1 className="text-4xl font-bold mb-8 text-center text-gray-900 dark:text-white">
        Confirm Your Email
      </h1>
      {error && <p className="text-red-500 mb-4 text-center">{error}</p>}
      <form onSubmit={handleSubmit} className="max-w-md mx-auto">
        <div className="mb-4">
          <label htmlFor="confirmationCode" className="block mb-2 text-gray-800 dark:text-gray-200">
            Enter confirmation code from your email
          </label>
          <input
            type="text"
            id="confirmationCode"
            value={confirmationCode}
            onChange={(e) => setConfirmationCode(e.target.value)}
            className="w-full px-3 py-2 border rounded bg-white dark:bg-gray-800 text-gray-900 dark:text-white"
            required
            autoComplete="one-time-code"
          />
        </div>
        <button 
          type="submit" 
          className="w-full bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition duration-300"
          disabled={loading}
        >
          {loading ? 'Confirming...' : 'Confirm Sign Up'}
        </button>
      </form>
    </div>
  );
};

export default ConfirmSignUp;