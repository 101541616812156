import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCurrentUser, fetchUserAttributes, updateUserAttributes, signOut, setUpTOTP, verifyTOTPSetup, updateMFAPreference } from 'aws-amplify/auth';
import { QRCodeSVG } from 'qrcode.react';

const AccountManagement = () => {
  const [userAttributes, setUserAttributes] = useState({});
  const [loading, setLoading] = useState(true);
  const [mfaEnabled, setMfaEnabled] = useState(false);
  const [showTotpSetup, setShowTotpSetup] = useState(false);
  const [qrCode, setQrCode] = useState('');
  const [totpSecret, setTotpSecret] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchUser();
  }, []);

  const fetchUser = async () => {
    try {
      const currentUser = await getCurrentUser();
      const attributes = await fetchUserAttributes();
      setUserAttributes(attributes);
      setMfaEnabled(attributes['custom:mfa_preference'] === 'totp');
      setLoading(false);
    } catch (error) {
      console.error('Error fetching user data:', error);
      setLoading(false);
    }
  };

  const handleUpdateProfile = async (e) => {
    e.preventDefault();
    try {
      await updateUserAttributes({
        userAttributes: {
          name: userAttributes.name,
          email: userAttributes.email,
          'custom:organization': userAttributes['custom:organization'],
          'custom:position': userAttributes['custom:position'],
        }
      });
      setError('Profile updated successfully');
    } catch (error) {
      setError('Failed to update profile');
    }
  };

  const handleChange = (e) => {
    setUserAttributes({ ...userAttributes, [e.target.name]: e.target.value });
  };

  const handleToggleMfa = async () => {
    if (mfaEnabled) {
      try {
        await updateUserAttributes({
          userAttributes: {
            'custom:mfa_preference': 'none',
          }
        });
        setMfaEnabled(false);
        setError('MFA disabled successfully');
      } catch (error) {
        setError('Failed to disable MFA');
      }
    } else {
      setupTOTP();
    }
  };

  const setupTOTP = async () => {
    try {
      const setupResult = await setUpTOTP();
      const secretKey = setupResult.SharedSecret;
      if (!secretKey) {
        throw new Error('No secret key received');
      }

      await updateMFAPreference({
        preferred: 'TOTP',
        enabled: true
      });

      const userIdentifier = encodeURIComponent(userAttributes.email || userAttributes.username);
      const encodedIssuer = encodeURIComponent('Observant-Website');
      const totpUri = `otpauth://totp/${encodedIssuer}:${userIdentifier}?secret=${secretKey}&issuer=${encodedIssuer}`;
      
      setTotpSecret(secretKey);
      setQrCode(totpUri);
      setShowTotpSetup(true);
    } catch (error) {
      console.error('TOTP Setup Error:', error);
      setError('Failed to setup TOTP: ' + error.message);
    }
  };

  const handleVerifyTotp = async (e) => {
    e.preventDefault();
    setError('');
    
    try {
      await verifyTOTPSetup({
        code: verificationCode
      });

      await updateUserAttributes({
        userAttributes: {
          'custom:mfa_preference': 'totp',
        }
      });

      setMfaEnabled(true);
      setShowTotpSetup(false);
      setError('TOTP MFA setup successful');
    } catch (error) {
      setError('Failed to verify TOTP code');
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut();
      navigate('/');
    } catch (error) {
      setError('Failed to sign out');
    }
  };

  if (loading) return <div className="container mx-auto px-6 py-12">Loading...</div>;

  return (
    <div className="container mx-auto px-6 py-12">
      <h1 className="text-4xl font-bold mb-8">Account Management</h1>
      {error && <p className="text-red-500 mb-4">{error}</p>}

      <div className="max-w-md mx-auto">
        <h2 className="text-2xl font-semibold mb-4">Profile Information</h2>
        <form onSubmit={handleUpdateProfile}>
          <div className="mb-4">
            <label className="block mb-2">Name</label>
            <input
              type="text"
              name="name"
              value={userAttributes.name || ''}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">Email</label>
            <input
              type="email"
              name="email"
              value={userAttributes.email || ''}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">Organization</label>
            <input
              type="text"
              name="custom:organization"
              value={userAttributes['custom:organization'] || ''}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">Position</label>
            <input
              type="text"
              name="custom:position"
              value={userAttributes['custom:position'] || ''}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded"
            />
          </div>
          <button type="submit" className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700">
            Update Profile
          </button>
        </form>

        <h2 className="text-2xl font-semibold mt-8 mb-4">Security Settings</h2>
        <div className="flex items-center justify-between mb-4">
          <span>Multi-Factor Authentication (TOTP)</span>
          <button
            onClick={handleToggleMfa}
            className={`py-2 px-4 rounded ${mfaEnabled ? 'bg-red-500 hover:bg-red-600' : 'bg-green-500 hover:bg-green-600'} text-white`}
          >
            {mfaEnabled ? 'Disable MFA' : 'Enable MFA'}
          </button>
        </div>

        {showTotpSetup && (
          <div className="mt-4">
            <h3 className="text-xl font-semibold mb-2">Set up Two-Factor Authentication</h3>
            {qrCode && (
              <>
                <div className="mb-6">
                  <p className="mb-2">Scan this QR code with your authenticator app:</p>
                  <QRCodeSVG value={qrCode} size={200} className="mb-4 mx-auto" />
                </div>
                <div className="mb-6 p-4 border rounded bg-gray-50 dark:bg-gray-800">
                  <p className="font-semibold mb-2">Or enter this secret key manually:</p>
                  <div className="font-mono bg-white dark:bg-gray-900 p-3 rounded-md border text-lg text-center select-all">
                    {totpSecret}
                  </div>
                  <p className="text-sm text-gray-600 dark:text-gray-400 mt-2">
                    Enter this secret key in your authenticator app if you can't scan the QR code
                  </p>
                </div>
              </>
            )}
            <form onSubmit={handleVerifyTotp}>
              <div className="mb-4">
                <label className="block mb-2">Enter 6-digit code from authenticator app</label>
                <input
                  type="text"
                  value={verificationCode}
                  onChange={(e) => setVerificationCode(e.target.value)}
                  className="w-full px-3 py-2 border rounded"
                  required
                  maxLength="6"
                  pattern="\\d{6}"
                />
              </div>
              <button type="submit" className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700">
                Verify and Enable MFA
              </button>
            </form>
          </div>
        )}

        <button
          onClick={handleSignOut}
          className="mt-8 bg-red-600 text-white py-2 px-4 rounded hover:bg-red-700"
        >
          Sign Out
        </button>
      </div>
    </div>
  );
};

export default AccountManagement;