import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signIn, confirmSignIn } from 'aws-amplify/auth';
import { useAuth } from '../context/AuthContext';

const Login = () => {
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    mfaCode: '',
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showMfaPrompt, setShowMfaPrompt] = useState(false);
  const [challengeType, setChallengeType] = useState('');
  const navigate = useNavigate();
  const { setIsAuthenticated } = useAuth();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      const { nextStep } = await signIn({
        username: formData.username,
        password: formData.password,
      });

      switch (nextStep.signInStep) {
        case 'DONE':
          setIsAuthenticated(true);
          navigate('/dashboard');
          break;
        case 'CONFIRM_SIGN_IN_WITH_TOTP_MFA':
          setShowMfaPrompt(true);
          setChallengeType('TOTP');
          break;
        case 'CONFIRM_SIGN_IN_WITH_SMS_MFA':
          setShowMfaPrompt(true);
          setChallengeType('SMS');
          break;
        default:
          throw new Error('Unsupported authentication step');
      }
    } catch (err) {
      console.error('Login error:', err);
      handleLoginError(err);
    } finally {
      setLoading(false);
    }
  };

  const handleLoginError = (err) => {
    if (err.name === 'UserNotConfirmedException') {
      navigate('/confirm-signup', { 
        state: { username: formData.username } 
      });
      return;
    }

    const errorMessages = {
      NotAuthorizedException: 'Incorrect username or password.',
      UserNotFoundException: 'User does not exist.',
      default: 'An error occurred during login.'
    };

    setError(errorMessages[err.name] || err.message || errorMessages.default);
  };

  const handleMfaSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      const { isSignedIn } = await confirmSignIn({
        challengeResponse: formData.mfaCode
      });

      if (isSignedIn) {
        setIsAuthenticated(true);
        navigate('/dashboard');
      } else {
        setError('MFA verification failed. Please try again.');
      }
    } catch (err) {
      console.error('MFA verification error:', err);
      setError(err.message || 'Failed to verify MFA code.');
    } finally {
      setLoading(false);
    }
  };

  if (showMfaPrompt) {
    return (
      <div className="container mx-auto px-6 py-12 bg-gray-100 dark:bg-gray-900">
        <h1 className="text-4xl font-bold mb-8 text-center text-gray-900 dark:text-white">
          Enter MFA Code
        </h1>
        {error && <p className="text-red-500 mb-4 text-center">{error}</p>}
        <form onSubmit={handleMfaSubmit} className="max-w-md mx-auto">
          <div className="mb-4">
            <label htmlFor="mfaCode" className="block mb-2 text-gray-800 dark:text-gray-200">
              {challengeType === 'TOTP' 
                ? 'Enter Google Authenticator Code' 
                : 'Enter SMS Code'}
            </label>
            <input
              type="text"
              id="mfaCode"
              name="mfaCode"
              value={formData.mfaCode}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded bg-white dark:bg-gray-800 text-gray-900 dark:text-white"
              required
              autoComplete="one-time-code"
              inputMode="numeric"
              pattern="\\d{6}"
              maxLength="6"
            />
          </div>
          <button 
            type="submit" 
            className="w-full bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition duration-300"
            disabled={loading}
          >
            {loading ? 'Verifying...' : 'Verify MFA'}
          </button>
        </form>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-6 py-12 bg-gray-100 dark:bg-gray-900">
      <h1 className="text-4xl font-bold mb-8 text-center text-gray-900 dark:text-white">
        Login
      </h1>
      {error && <p className="text-red-500 mb-4 text-center">{error}</p>}
      <form onSubmit={handleSubmit} className="max-w-md mx-auto">
        <div className="mb-4">
          <label htmlFor="username" className="block mb-2 text-gray-800 dark:text-gray-200">
            Username
          </label>
          <input
            type="text"
            id="username"
            name="username"
            value={formData.username}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded bg-white dark:bg-gray-800 text-gray-900 dark:text-white"
            required
            autoComplete="username"
          />
        </div>
        <div className="mb-6">
          <label htmlFor="password" className="block mb-2 text-gray-800 dark:text-gray-200">
            Password
          </label>
          <input
            type="password"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded bg-white dark:bg-gray-800 text-gray-900 dark:text-white"
            required
            autoComplete="current-password"
          />
        </div>
        <button 
          type="submit" 
          className="w-full bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition duration-300"
          disabled={loading}
        >
          {loading ? 'Logging in...' : 'Log In'}
        </button>
      </form>
    </div>
  );
};

export default Login;